.gallery {
    display: flex;
    flex-direction: column;
    padding: 150px 30px 50px;
}

@media (max-width: 1139px) {
    .gallery {
        padding: 20px 30px 50px;
    }
}

.gallery__wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: baseline;
}

.gallery__wrapper>img {
    margin-bottom: 50px;
    cursor: pointer;
}

@media (max-width: 1139px) {
    .gallery__wrapper>img {
        width: 100% !important;
        height: auto !important;
    }
}

@media (min-width: 1140px) {
    .gallery__wrapper>img {
        width: 45% !important;
        height: auto !important;
    }
}

.gallery__title {
    font-size: 54px;
    line-height: 64px;
    font-weight: 700;
    color: #161922;
    margin-left: 200px;
    margin-right: auto;
}

@media (max-width: 767px) {
    .gallery__title {
        margin-left: 0;
        font-size: 24px;
        line-height: 1.2;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .gallery__title {
        margin-left: 0;
        font-size: 44px;
        line-height: 1.2;
    }
}

.gallery__contacts {
    font-size: 54px;
    line-height: 64px;
    font-weight: 700;
    color: #161922;
    margin-left: 200px;
    margin-right: auto;
    width: 500px;
}

@media (max-width: 767px) {
    .gallery__contacts {
        margin-left: 0;
        font-size: 24px;
        line-height: 1.2;
        width: auto;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .gallery__contacts {
        margin-left: 0;
        font-size: 44px;
        line-height: 1.2;
        width: auto;
    }
}

.gallery__contacts-wrapper {
    font-size: 19px;
    line-height: 27px;
    font-weight: 700;
    color: #161922;
    margin-left: 200px;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    width: 400px;
}

@media (max-width: 767px) {
    .gallery__contacts-wrapper {
        flex-direction: column;
        margin-left: 0;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .gallery__contacts-wrapper {
        margin-left: 0;
    }
}