.header__nav {
    display: flex;
    margin: 0;
    margin-left: auto;
    margin-right: 50px;
    padding: 0;
    list-style: none;
}

.header__item {
    margin-right: 20px;
    position: relative;
}

@media (max-width: 1139px) {
    .header__item {
        padding: 5px;
    }
}

.header__link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    height: 40px;
    display: flex;
    align-items: center;
}

.header__link:hover {
    color: #FFD800;
}

.header__subnav {
    display: none;
    position: absolute;
    top: 30px;
    left: -20px;
    padding: 10px;
}

.subnav {
    display: block;
    min-width: 250px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #FFD800;
    margin: 0;
    list-style: none;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 5px #10293d;
    box-shadow: 0px 0px 50px 5px #10293d;
}

.subnav li {
    padding: 5px;
}

.subnav a {
    color: #696687;
    font-size: 0.875em;
    line-height: 1.65;
    text-decoration: none;
}

.subnav a:hover {
    color: #FFD800;
}

.header__item:hover .header__subnav {
    display: block;
}

@media (max-width: 767px) {
    .header__item:hover .header__subnav {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .header__item:hover .header__subnav {
        left: -330px;
        top: -30px;
    }
}

.menuToggle {
    display: none;
    transform-origin: 16px 11px;
    outline: 0;
    border: 0;
    padding: 12px 12px 6px;
    background: none;
}

.menuToggle span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: block;
    width: 40px;
    height: 5px;
    margin-bottom: 7px;
    background-color: #FFD800;
}

.menuToggle:focus {
    outline: 0;
}

.menuToggle:hover span:nth-of-type(1){
    width: 33px;
}

.menuToggle:hover span:nth-of-type(2){
    width: 40px;
}

.menuToggle:hover span:nth-of-type(3){
    width: 30px;
}

.menuOpened+.menuToggle span:nth-of-type(1){
    transform: rotate(45deg) translate(10px, 10px);
    width: 40px;
}

.menuOpened+.menuToggle span:nth-of-type(2){
    opacity: 0;
    pointer-events: none;
}

.menuOpened+.menuToggle span:nth-of-type(3){
    transform: rotate(-45deg) translate(7px, -7px);
    width: 40px;
}

@media (max-width: 1139px) {
    .menuToggle {
        display: block;
    }

    .menuClosed {
        display: none;
    }

    .menuOpened {
        padding: 20px;
        display: block;
        position: absolute;
        background-color: #ffffff;
        top: 90px;
        right: 20px;
        margin-right: 0;
        border: 1px solid #FFD800;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 50px 5px #10293d;
        box-shadow: 0px 0px 50px 5px #10293d;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .menuToggle {
        margin-left: auto;
        margin-right: 30px;
    }

    .menuOpened {
        right: 200px;
    }
}