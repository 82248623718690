.gallery-wrapper {
    z-index: 0;
}

.swiper-container {
    z-index: 0;
}

.swiper-slide img {
    width: 100%;
}