/*.tel {*/
/*    margin-left: auto;*/
/*    color: #ffffff;*/
/*    text-decoration: none;*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*}*/

/*@media (max-width: 767px) {*/
/*    .tel {*/
/*        margin-left: 0;*/
/*    }*/
/*}*/

.tel {
    padding: 11px 20px;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: white;
    background-color: #10293d;
}

@media (max-width: 767px) {
    .tel__wrapper {
        display: none;
    }
}