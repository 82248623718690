.hero {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 880px;
    background-image: url("../../assets/main-bg.png");
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .hero {
        min-height: auto;
        background-size: 767px auto;
        background-position: 50% 50%;
        margin-bottom: 80px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .hero {
        background-position: 50% 50%;
        min-height: 550px;
    }
}

@media (min-width: 1140px) {
    .hero {
        padding: 0 20px;
    }
}

.hero__wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
}

@media (max-width: 767px) {
    .hero__wrapper {
        min-height: 250px;
        padding: 20px 20px;
        text-align: center;
        position: relative;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .hero__wrapper {
        padding: 20px;
    }
}

.hero__title {
    width: 65%;
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
    color: rgb(16, 41, 61);
}

@media (max-width: 767px) {
    .hero__title {
        text-align: center;
        font-size: 30px;
        line-height: 1.2;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .hero__title {
        font-size: 45px;
        line-height: 1.2;
    }
}

.hero__subtitle {
    margin-bottom: 60px;
    width: 65%;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: rgb(16, 41, 61);
}

@media (max-width: 767px) {
    .hero__subtitle {
        display: none;
    }
}

.hero__wrapper>button {
    background-color: rgb(255, 216, 0);
    font-family: 'Open Sans', sans-serif;
    line-height: 40px;
    font-weight: 700;
    font-size: 16px;
    padding: 4px 40px;
    color: rgb(16, 41, 61);
    border: none;
}

@media (max-width: 767px) {
    .hero__wrapper>button {
        padding: 4px 10px;
    }
}

.hero__wrapper>button:hover {
    background-color: #10293d;
    color: #ffffff;
}

.hero__line {
    margin-top: 60px;
    margin-bottom: 30px;
    height: 2px;
    width: 400px;
    background-color: rgb(218, 218, 218);
}

@media (max-width: 767px) {
    .hero__line {
        display: none;
    }
}

.social {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 767px) {
    .social {
        justify-content: center;
        position: absolute;
        width: 280px;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.social p {
    font-family: 'Open Sans', sans-serif;
    line-height: 23px;
    font-weight: 400;
    font-size: 15px;
    color: rgba(16, 41, 61, 0.79);
}

@media (max-width: 767px) {
    .social p {
        display: none;
    }
}

.social ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.social ul li {
    background-color: rgb(242, 242, 242);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social ul li:not(:last-of-type) {
    margin-right: 10px;
}

.social ul a {
    color: rgba(16, 41, 61, 0.79);
}