.about {
    display: flex;
}

@media (min-width: 768px) and (max-width: 1139px) {
    .about {
        margin-top: 30px;
    }
}

.about__wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 1140px;
    align-items: end;
}

@media (max-width: 767px) {
    .about__wrapper {
        flex-direction: column;
    }
}

.about__wrapper div:first-of-type {
    height: 562px;
}

@media (max-width: 767px) {
    .about__wrapper div:first-of-type {
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .about__wrapper div:first-of-type{
        height: auto;
    }
}

.about__wrapper img {
    width: 562px;
}

@media (max-width: 767px) {
    .about__wrapper img {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .about__wrapper img {
        width: 342px;
    }
}

.about__desc {
    padding: 70px 60px 50px 80px;
    box-sizing: border-box;
    z-index: -1;
    margin-left: -40px;
    background-color: #FFD800;
    min-height: 562px;
}

@media (max-width: 767px) {
    .about__desc {
        margin-top: -4px;
        margin-left: 0;
        padding: 40px 20px 40px 20px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .about__desc {
        padding: 30px 30px 20px 65px;
        margin-top: -4px;
        min-height: 346px;
    }
}

.about__title {
    font-size: 25px;
    line-height: 34px;
    font-weight: 700;
    color: #161922;
}

@media (max-width: 767px) {
    .about__title {
        font-size: 22px;
        line-height: 1.2;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .about__title {
        font-size: 19px;
        line-height: 1.2;
    }
}

.about__text {
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

@media (min-width: 768px) and (max-width: 1139px) {
    .about__text {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 32px;
    }
}

.about button {
    padding: 11px 20px;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    color: white;
    background-color: #10293d;
    border: none;
}

.about button:hover {
    color: #FFD800;
}