.main-gallery {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

/*@media (max-width: 1139px) {*/
/*    .main-gallery {*/
/*        display: none;*/
/*    }*/
/*}*/

.main-gallery__wrapper {
    margin-left: auto;
    margin-right: auto;
}

.main-gallery__title {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 30px;
    line-height: 40px;
}

@media (max-width: 767px) {
    .main-gallery__title {
        text-align: center;
    }
}

.main-gallery .tel__wrapper {
    display: flex;
    justify-content: center;
    margin-top: 35px;
}

.main-gallery__text {
    margin-top: 35px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    text-align: center;
}

@media (max-width: 767px) {
    .main-gallery__text {
        width: 100%;
        padding: 0 20px;
        margin-left: 0;
        margin-right: 0;
        box-sizing: border-box;
    }
}