.simple-text {
    font-size: 25px;
    line-height: 34px;
    font-weight: 700;
    color: #161922;
    display: flex;
}

@media (max-width: 767px) {
    .simple-text {
        margin-top: -400px;
        margin-bottom: 50px;
        font-size: 16px;
        line-height: 26px;
        font-weight: 400;
        padding: 0 20px;
    }
}

@media (min-width: 767px) and (max-width: 1140px) {
    .simple-text {
        padding: 0 20px;
    }
}

.simple-text p {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}