.service {
    margin-top: 100px;
    display: flex;
}

@media (max-width: 767px) {
    .service {
        margin-top: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .service {
        margin-top: 40px;
    }
}

@media (min-width: 1139px) {
    .service {
        padding: 0 20px;
    }
}

.service__wrapper {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1139px) {
    .service__wrapper {
        padding: 0 20px;
    }
}

.service__title {
    margin-bottom: 70px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    width: 40%;
    color: #161922;
}

@media (max-width: 767px) {
    .service__title {
        width: 100%;
        font-size: 25px;
        line-height: 1.2;
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .service__title {
        width: 80%;
    }
}

.features__wrapper {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .features__wrapper {
        flex-direction: column;
    }
}

.features__item {
    width: 33%;
}

@media (max-width: 767px) {
    .features__item {
        width: 100%;
        text-align: center;
    }
}

.features__item:not(:last-of-type) {
    margin-right: 60px;
}

@media (max-width: 767px) {
    .features__item:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .features__item:not(:last-of-type) {
        margin-right: 30px;
    }
}

.features__item img {
    width: 204px;
}

.features__item h4 {
    margin-bottom: 0;
    color: #161922;
    font-size: 25px;
    line-height: 34px;
    font-weight: 700;
}

.features__item p {
    color: #10293d;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.features__item span {
    color: #161922;
    font-size: 15px;
    line-height: 25px;
    font-weight: 700;
}