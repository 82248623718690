.contactUs {
    margin-top: 100px;
    display: flex;
}

@media (max-width: 767px) {
    .contactUs {
        margin-top: 50px;
    }
}

.contactUs__wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .contactUs__wrapper {
        flex-direction: column-reverse;
        padding: 0 20px;
        text-align: center;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .contactUs__wrapper {
        padding: 0 20px;
    }
}

.contactUs__wrapper img {
    width: 456px;
    height: auto;
    margin-right: 40px;
}

@media (max-width: 767px) {
    .contactUs__wrapper img {
        width: 280px;
        height: auto;
        margin-right: 0px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .contactUs__wrapper img {
        width: 356px;
    }
}

.contactUs__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contactUs__title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #161922;
}

@media (max-width: 767px) {
    .contactUs__title {
        font-size: 22px;
        line-height: 1.2;
    }
}

.contactUs__desc div {
    margin: 35px 0;
}

@media (max-width: 767px) {
    .contactUs__desc div {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
    }
}

.contactUs__desc span {
    font-size: 19px;
    line-height: 27px;
    font-weight: 700;
    color: #161922;
}

.contactUs__desc span:not(:last-of-type) {
    margin-right: 30px;
}

@media (max-width: 767px) {
    .contactUs__desc span:not(:last-of-type) {
        margin-right: 0px;
    }
}

.contactUs__desc p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}
