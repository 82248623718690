.header {
    padding: 20px 100px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    background-color: #FFFFFF;
}

@media (max-width: 767px) {
    .header {
        padding: 20px;
        position: relative;
    }
}

@media (min-width: 768px) and (max-width: 1139px){
    .header {
        padding: 30px;
    }
}

.header__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
}

@media (max-width: 767px) {
    .header__wrapper {
        justify-content: space-between;
    }
}

@media (min-width: 1140px) {
    .header {
        position: fixed;
    }

    .header__shadow {
        box-shadow: 0px 8px 6px -7px rgba(0, 0, 0, 0.3);
    }

}

