/*.footer {*/
/*    margin-top: -122px;*/
/*}*/

/*@media (max-width: 767px) {*/
/*    .footer {*/
/*        margin-top: 0;*/
/*    }*/
/*}*/

/*.footer__wrapper {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    background-color: #223171;*/
/*}*/

/*.footer__down {*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*    padding-top: 30px;*/
/*    padding-bottom: 30px;*/
/*    width: 1170px;*/
/*    display: flex;*/
/*    color: #ffffff;*/
/*    border-top: 1px solid rgba(255, 255, 255, 0.1);*/
/*    border-bottom: 1px solid rgba(255, 255, 255, 0.1);*/
/*}*/

/*@media (min-width: 768px) and (max-width: 1170px) {*/
/*    .footer__down {*/
/*        box-sizing: border-box;*/
/*        padding-left: 15px;*/
/*        padding-right: 15px;*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*@media (max-width: 767px) {*/
/*    .footer__down {*/
/*        box-sizing: border-box;*/
/*        padding-left: 15px;*/
/*        padding-right: 15px;*/
/*        flex-direction: column;*/
/*        width: 100%;*/
/*    }*/
/*}*/

.footer {
    display: flex;
    background-image: url("../../../assets/reno4-footer-bg.jpeg");
}

.footer__wrapper {
    padding-top: 80px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .footer__wrapper {
        flex-wrap: wrap;
        padding: 50px 20px 50px;
    }
}

@media (min-width: 767px) and (max-width: 1140px) {
    .footer__wrapper {
        padding: 50px 20px 50px;
    }
}

.footer__wrapper div{
    width: 30%;
}

.footer__wrapper div:last-of-type {
    width: 40%;
}

.footer__wrapper div:not(:last-of-type) {
    margin-right: 50px;
}

.footer__wrapper h4 {
    color: #10293d;
    font-size: 19px;
    line-height: 27px;
    font-weight: 700;
}

.footer__wrapper span {
    color: #10293d;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}

.footer__wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer__wrapper a {
    text-decoration: none;
    color: #10293d;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

.footer__wrapper a:hover {
    text-decoration: underline;
    color: #164673;
}

.footer__wrapper h5 {
    margin: 0;
}

.footerTel {
    font-weight: 700;
    color: #10293d;
    font-size: 25px;
    line-height: 34px;
}