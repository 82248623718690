.tsp {
    display: flex;
    position: relative;
    min-height: 727px;
    background-image: url("../../assets/workers.png");
    z-index: -1;
}

@media (max-width: 767px) {
    .tsp {
        background-position: 1150px 0px;
    }
}

@media (min-width: 767px) and (max-width: 1140px) {
    .tsp {
        background-position: 1300px 0px;
    }
}

.tsp__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 767px) {
    .tsp__wrapper {
        padding: 0 20px;
        width: 100%;
        justify-content: flex-start;
    }
}

@media (min-width: 767px) and (max-width: 1140px) {
    .tsp__wrapper {
        padding: 0 20px;
        justify-content: flex-start;
    }
}

/*.tsp img {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*    display: none;*/
/*}*/

/*@media (max-width: 767px) {*/
/*    .tsp img {*/
/*        right: -800px;*/
/*    }*/
/*}*/

.tsp h1 {
    width: 40%;
    font-size: 54px;
    line-height: 64px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .tsp h1 {
        font-size: 34px;
        line-height: 1.2;
        width: 100%;
    }
}

@media (min-width: 767px) and (max-width: 1140px) {
    .tsp h1 {
        margin-top: 150px;
        width: 60%;
    }
}

.tsp span {
    max-width: 500px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}