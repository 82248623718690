.feature-items {
    display: flex;
}

@media (max-width: 767px) {
    .feature-items {
        margin-top: -400px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-items {
        margin-top: 30px;
    }
}

.feature-items__wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    align-items: end;
    padding: 0 20px;
}

@media (max-width: 767px) {
    .feature-items__wrapper {
        padding: 0 30px;
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-items__wrapper {
        padding: 0 30px;
    }
}

.feature-item {
    display: flex;
    margin-bottom: 130px;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 767px) {
    .feature-item {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
    }
}

.feature-item div:first-of-type {
    height: 469px;
}

@media (max-width: 767px) {
    .feature-item div:first-of-type {
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-item div:first-of-type{
        height: auto;
    }
}

.feature-item img {
    width: 469px;
}

@media (max-width: 767px) {
    .feature-item img {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-item img {
        width: 298px;
    }
}

.feature-item__desc {
    box-sizing: border-box;
    width: 50%;
    margin-left: auto;
}

@media (max-width: 767px) {
    .feature-item__desc {
        margin-left: 0;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-item__desc {

    }
}

.feature-item__title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #161922;
}

@media (max-width: 767px) {
    .feature-item__title {
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-item__title {
        font-size: 19px;
        line-height: 1.2;
    }
}

.feature-item__text {
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
}

@media (max-width: 767px) {
    .feature-item__text {
        font-size: 13px;
        text-align: center;
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1139px) {
    .feature-item__text {
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 32px;
    }
}

.feature-item__list {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.feature-item__list li {
    width: 50%;
    margin-bottom: 10px;
}

.feature-item__list li:before {
    content: '✓';
    color: #FFD800;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .feature-item__list {
        font-size: 13px;

    }
}

.feature-item__price {
    font-size: 19px;
    line-height: 27px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .feature-item__price {
        text-align: center;
    }
}